home-categorias {
  .categorias {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 40px 10%;
    overflow: auto;
    @media all and (max-width: $btMD) {
      flex-direction: column;
      margin: 10px;
    }
    .categoria {
      height: 80vh;
      width: 33.333%;
      min-width: 33.333%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: #000;
      position: relative;
      overflow: hidden;
      @media all and (max-width: $btMD) {
        width: 100%;
        height: 30vh;
        justify-content: center;
      }
      &:hover {
        img.fondo {
          filter: brightness(1.1);
          -webkit-filter: brightness(1.1);
        }
      }
      img.fondo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include ease-transition(400ms);
      }
      .top {
        margin: 80px 0 0 0;
        width: 100%;
        position: relative;
        @media all and (max-width: $btMD) {
          margin: 0;
        }
        .titulo {
          text-align: center;
          color: #323232;
          font-family: "Brandon-Grothesque-Black";
          font-size: 200%;
          line-height: 100%;
        }
        .subtitulo {
          text-align: center;
          font-family: "TitilliumWeb-Regular";
          font-size: 120%;
        }
      }
      .bottom {
        width: 70%;
        margin: 0 0 20px 0;
        position: relative;
        @media all and (max-width: $btMD) {
          margin: 0;
        }
        .brand {
          padding: 2px 40px;
          width: 100%;
          text-align: center;
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          font-family: "Brandon-Grothesque-Black";
          letter-spacing: 1em;
          font-size: 116%;
        }
      }
      &.blanco {
        .top {
          .titulo {
            color: #ffffff;
            @media all and (max-width: $btMD) {
              text-shadow: 0 0 2px #000;
            }
          }
          .subtitulo {
            color: #ffffff;
            @media all and (max-width: $btMD) {
              text-shadow: 0 0 2px #000;
            }
          }
        }
        .bottom {
          .brand {
            color: #ffffff;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            @media all and (max-width: $btMD) {
              text-shadow: 0 0 2px #000;
            }
          }
        }
      }
    }
  }
}
