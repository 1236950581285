@import "../config";

body.page-carrito {
  h1 {
    text-transform: uppercase;
  }
  .tabla-productos {
    .productos {
      .cantidad {
        display: flex;
        justify-content: center;
        align-items: center;
        .controles {
          width: 37px;
          display: flex;
          font-size: 90%;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #000;
          cursor: pointer;
          @include ease-transition(150ms);

          i.fa {
            cursor: pointer;
            opacity: 0.3;

            &:hover {
              opacity: 1;
            }
          }

          i.fa-caret-up {
            margin-bottom: 5px;
          }

          i.fa-caret-down {
            margin-top: 5px;
          }
        }
      }
      .precio {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      td {
        vertical-align: middle;
      }
      .imagen {
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          display: block;
          width: 100px;
          height: 100px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        span {
          font-size: 300%;
        }
      }
      .titulo {
        a {
          color: #000;
        }
      }

      .precios {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .total {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .borrar {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .btn {
          padding: 5px;
          padding-left: 7px;
          padding-right: 7px;
        }
        i.fa {
          font-size: 160%;
          color: #f66;
          cursor: pointer;
        }
        .seguro {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .comprar {
      width: 100%;
      background-color: #435665;
      border: 0;
      &:active {
        background-color: #546776;
      }
    }
  }

  @import "form-presupuesto";
}
