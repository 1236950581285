tarjeta-producto {
  height: auto;
  margin: 20px;
  min-width: 220px;
  margin-right: 5px;
  @media all and (max-width: $btMD) {
    margin: 0 10px;
    margin-bottom: 20px;
  }
  .tarjeta-producto {
    width: 220px;
    @media all and (max-width: $btMD) {
      min-width: 220px !important;
      margin: 0 auto;
    }
    &:hover {
      a.top-image {
        @include sombra(0 0 3px 0 #888);
        // &::before {
        //   background-color: transparent;
        //   @media all and (max-width: $mqMobileXL) {
        //     background-color: rgba(#000, 0.2);
        //   }
        // }
      }
    }
    a.top-image {
      @include ease-transition;
      width: 220px;
      height: 220px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      cursor: pointer;
      color: #000;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      @include sombra(0 0 2px 0 #ccc);
      @include ease-transition;
      @media all and (max-width: $mqMobileXL) {
        width: 100%;
        background-size: contain;
      }
      // &:before {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   background-color: rgba(#000, 0.1);
      //   @include ease-transition;
      // }
      .no-pic {
        opacity: 0.2;
        font-size: 300%;
      }
      .fav-heart {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .detalles {
      font-family: TitilliumWeb-Regular;
      a.tit {
        margin-top: 0.8rem;
        font-size: 0.9em;
        color: #000;
        text-decoration: none;
      }
      .rating {
        color: #ffcc00;
        span {
          cursor: pointer;
        }
        span.porcentaje {
          color: black;
        }
      }
    }
  }
}
