@import "../config";

body[class^="page-compras-"] {
  section.contenido {
    .contenido-compra-show {
      position: relative;
      overflow: hidden;
      @media all and (max-width: $btMD) {
        padding-bottom: 60px;
      }
      .titulo-principal {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 50px;
        width: 85%;
        @media all and (max-width: $btMD) {
          margin-top: 15px;
          text-align: center;
        }
        &::before,
        &::after {
          content: "";
          display: block;
          height: 5px;
          width: 100px;
          margin: 0 25px;
          background-color: #000;
        }
        & > span {
          text-transform: uppercase;
          color: #000;
          font-family: "verdana";
          font-size: 230%;
          @media all and (max-width: $btMD) {
            font-size: 190%;
          }
        }
      }
      .productos {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin: 50px auto;
        @media all and (max-width: $btMD) {
          width: 95%;
        }
        .cabecera {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0;
          margin: 0 2px;
          @media all and (max-width: $btMD) {
            flex-wrap: wrap;
          }
          .imagen {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            border-left: 0;
            border-right: 0;
            padding: 0 3px;
            color: #000;
            @media all and (max-width: $btMD) {
              width: 25%;
              height: auto;
              display: none;
            }
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .nombre {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 65%;
            color: #000;
            outline: 0;
            padding: 0 10px;
            color: #000;
            @media all and (max-width: $btMD) {
              width: 75%;
              height: auto;
              display: none;
            }
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .cantidad {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            color: #000;
            padding: 0 7px;
            @media all and (max-width: $btMD) {
              width: 33.333%;
              height: auto;
            }
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .precio {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            color: #000;
            padding: 0 3px;
            @media all and (max-width: $btMD) {
              width: 33.333%;
              height: auto;
            }
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .entrega {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            color: #000;
            padding: 0 3px;
            @media all and (max-width: $btMD) {
              width: 33.333%;
              height: auto;
            }
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
        }
        .producto {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0;
          margin: 0 2px;
          @media all and (max-width: $btMD) {
            flex-wrap: wrap;
          }
          &:nth-of-type(even) {
            background-color: #dfdfdf;
          }
          .imagen {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            background-color: #fff;
            border-radius: 4px;
            border-left: 0;
            border-right: 0;
            padding: 0 3px;
            margin-left: 7px;
            @media all and (max-width: $btMD) {
              width: 23%;
              margin-left: 2%;
              height: 45px;
              min-height: 45px;
            }
            i {
              color: #888;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          a.nombre {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 65%;
            color: #000;
            outline: 0;
            padding: 0 7px;
            font-family: "verdana";
            color: #000;
            font-size: 95%;
            @media all and (max-width: $btMD) {
              width: 75%;
              height: auto;
            }
          }
          .cantidad {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            font-family: "verdana";
            color: #000;
            // border: 1px solid #ccc;
            // border-right: 0;
            padding: 0 7px;
            font-size: 95%;
            @media all and (max-width: $btMD) {
              width: 33.333%;
              height: auto;
              margin-top: 10px;
            }
          }
          .precio {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            font-family: "verdana";
            color: #000;
            padding: 0 3px;
            font-size: 95%;
            @media all and (max-width: $btMD) {
              width: 33.333%;
              height: auto;
              margin-top: 10px;
            }
          }
          .entrega {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            font-family: "verdana";
            color: #000;
            padding: 0 3px;
            font-size: 95%;
            @media all and (max-width: $btMD) {
              width: 33.333%;
              height: auto;
              margin-top: 10px;
            }
          }
        }
      }
      .flash {
        position: fixed;
        right: 25px;
        bottom: 25px;
        padding: 15px;
        padding-right: 35px;
        @include sombra(0 0 3px 0 #000);
        background-color: #f33;
        &.success {
          background-color: #20cc20;
        }
        .icono {
          position: absolute;
          right: 3px;
          top: 3px;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          @include minmaxwh(30px);
        }
        span {
          font-family: "verdana";
          color: #fff;
          font-size: 115%;
        }
      }
      .payment-section {
        position: fixed;
        bottom: -70px;
        right: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 170px;
        height: 110px;
        border-radius: 15px 15px 0 0;
        background-color: rgba(#000, 0.5);
        @include sombra(0 0 2px 0 #666);
        @include ease-transition;
        &:hover {
          bottom: 0;
        }
        &.attached {
          position: absolute;
        }
        @media all and (max-width: $btMD) {
          justify-content: center;
          width: 50vw;
          right: 25vw;
          bottom: -20px;
        }
        .payment-info {
          .realizado {
            padding: 3px 7px;
            span {
              text-transform: uppercase;
              font-size: 90%;
              font-family: "verdana";
              color: #000;
            }
          }
          .icono {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            img {
              object-fit: contain;
              width: auto;
              height: 100%;
            }
          }
          .collection-id {
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 80%;
              font-family: "verdana";
              color: #555;
            }
          }
        }
        .payment-button {
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: #000;
            border: 0;
            border-radius: 5px;
            padding: 5px 15px;
            cursor: pointer;
            outline: 0;
            text-decoration: none;
            @include ease-transition;
            &:hover {
              background-color: #000;
            }
            span {
              font-size: 65%;
              letter-spacing: 2px;
              color: #fff;
              font-family: "verdana";
              text-transform: uppercase;
            }
            img {
              @include minmaxwh(50px);
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
