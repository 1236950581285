@import "../config";

body.page-compras {
  section.contenido {
    .titulo-principal {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      @media all and (max-width: $btMD) {
        margin-top: 15px;
        text-align: center;
      }
      &::before,
      &::after {
        content: "";
        display: block;
        height: 5px;
        width: 100px;
        margin: 0 25px;
        background-color: #000;
      }
      span {
        text-transform: uppercase;
        color: #000;
        font-family: "verdana";
        font-size: 230%;
        @media all and (max-width: $btMD) {
          font-size: 190%;
        }
      }
    }
    .flash {
      position: fixed;
      right: 25px;
      bottom: 25px;
      padding: 15px;
      padding-right: 35px;
      @include sombra(0 0 3px 0 #000);
      background-color: #f33;
      .icono {
        position: absolute;
        right: 3px;
        top: 3px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        @include minmaxwh(30px);
      }
      span {
        font-family: "verdana";
        color: #fff;
        font-size: 115%;
      }
    }
    .lista-compras {
      width: 50%;
      margin: 30px auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      @media all and (max-width: $btMD) {
        width: 90%;
      }

      .no-compras {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
        text-align: center;
        span {
          font-size: 90%;
          color: #888;
        }
      }

      .compra {
        width: 28%;
        margin: ((100 / 3 - 28) / 2) * 1%;
        background-color: #dfdfdf;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        @include ease-transition;
        text-decoration: none;
        position: relative;
        &:hover {
          @include sombra(inset 0 0 0 2px #000);
        }
        @media all and (max-width: $btMD) {
          width: 46%;
          margin: ((100 / 2 - 46) / 2) * 1%;
        }
        .payment-info {
          position: absolute;
          right: 30 / 2 * -1px;
          bottom: 30 / 2 * -1px;
          @include minmaxwh(35px);
          .icono {
            width: 100%;
            height: 100%;
            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
        .fecha {
          margin: 10px 0;
          span {
            color: #555;
            font-family: "verdana";
          }
        }
        .icono-info {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .icono {
            width: 50%;
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              object-fit: contain;
            }
          }
          .info {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
            .item {
              display: flex;
              align-items: center;

              &::before {
                content: "";
                display: block;
                @include minmaxwh(5px);
                background-color: #000;
                margin-right: 5px;
                border-radius: 50%;
              }
              span {
                color: #000;
                font-family: "verdana";
                font-size: 100%;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
}
