@import "../config";

body.page-nosotros {
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 0.5627 * 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/assets/images/nosotros/header.jpg");
    padding-top: 15vw;
    @media all and (max-width: $btMD) {
      padding: 0;
      height: 45vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .titulo {
      @media all and (max-width: $btMD) {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      span {
        font-family: $fontBrandonLight;
        text-transform: uppercase;
        font-size: 300%;
        color: #000;
        @media all and (max-width: $btMD) {
          font-size: 200%;
        }
      }
    }
    .subtitulo {
      @media all and (max-width: $btMD) {
        width: 80%;
        margin: 10px auto;
        text-align: center;
      }
      span {
        font-family: $fontMontserratBold;
        text-transform: uppercase;
        font-size: 130%;
        color: #000;
      }
    }
  }
  .centro {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media all and (max-width: $btMD) {
      padding: 30px 0;
      flex-direction: column;
    }
    .columna {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media all and (max-width: $btMD) {
        width: 90%;
        margin: auto;
      }
      .titulo {
        @media all and (max-width: $btMD) {
          width: 100%;
          text-align: center;
        }
        span {
          font-family: $fontBrandonBlack;
          text-transform: uppercase;
          font-size: 180%;
          letter-spacing: 2px;
          color: #555;
          @media all and (max-width: $btMD) {
            font-size: 130%;
          }
        }
      }
      .cuerpo {
        width: 65%;
        @media all and (max-width: $btMD) {
          width: 100%;
        }
        p {
          font-family: $fontTitilliumWebRegular;
          font-size: 130%;
          text-align: center;
          color: #000;
          @media all and (max-width: $btMD) {
            text-align: justify;
            font-size: 100%;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100vw;
    height: 0.6233 * 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/assets/images/nosotros/bottom.jpg");
    @media all and (max-width: $btXL) {
      height: auto;
      background: #2e2e2e;
    }
    .contenido-bottom {
      width: 48%;
      margin-bottom: 7vw;
      @media all and (max-width: $btXL) {
        width: 90%;
        margin: 20px auto;
      }
      .titulo {
        @media all and (max-width: $btMD) {
          text-align: center;
        }
        span {
          font-family: $fontBrandonBlack;
          text-transform: uppercase;
          font-size: 180%;
          letter-spacing: 2px;
          color: #fff;
          @media all and (max-width: $btMD) {
            font-size: 150%;
          }
        }
      }
      .descripcion {
        width: 85%;
        @media all and (max-width: $btMD) {
          width: 95%;
          margin: auto;
        }
        p {
          white-space: pre-line;
          font-family: $fontTitilliumWebRegular;
          font-size: 130%;
          color: #fff;
          @media all and (max-width: $btMD) {
            font-size: 100%;
            text-align: justify;
          }
        }
      }
    }
  }
}
