login-carrito {
  display: none;
  position: fixed;
  top: 150px;
  right: 10px;
  &.hidden {
    display: none !important;
  }
  &.visible {
    display: flex;
  }
  &.flotante {
    top: 62px;
  }
  &.shouldFly {
    top: -20%;
    z-index: 1000;
  }
  &:before {
    content: "\f0d8";
    font-family: "FontAwesome";
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 50px;
    height: 30px;
    right: 20px;
    top: -33px;
    color: #fff;
    font-size: 45px;
    z-index: 8;
    text-shadow: 0 -1px 2px #999;
    @media all and (max-width: $btMD) {
      right: 80px;
    }
  }
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 50px;
    left: 0;
    top: -15px;
    z-index: 5;
  }
  .contenedor-cuadrado-redondo {
    width: 45vw;
    min-width: 550px;
    border-radius: 7px;
    overflow: hidden;
    z-index: 7;
    position: relative;
    padding-top: 35px;
    background-color: #fff;
    @include sombra(0 0 3px 0 #333);
    @media all and (max-width: $btMD) {
      width: 95vw;
      min-width: 0;
    }
    .close-row {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 10px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        @include minmaxwh(30px);
        border: 0;
        background-color: rgba(#333, 0.1);
        border-radius: 50%;
        cursor: pointer;
        @include ease-transition;
        position: relative;
        z-index: 10000;
        &:hover,
        &:active {
          background-color: rgba(#333, 0.2);
        }
        i {
          color: #444;
          @include not-selectable;
          cursor: pointer;
        }
      }
    }
    .user-logout {
      background-color: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px 0;
      .user,
      .logout {
        max-width: 50%;
        white-space: pre-line;
      }
      span,
      a {
        color: #000;
        font-family: $fontMontserratMedium;
      }
    }
    .contenedor-tiras {
      background-color: #fff;
      white-space: nowrap;
      width: 100%;
      height: auto;
      display: flex;
      @include ease-transition(1s);
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      &.show-login {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
      .lista-productos {
        z-index: 6;
        background-color: #fff;
        width: 100%;
        min-width: 100%;
        height: auto;
        .no-products {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 10px 0;
          span {
            font-size: 90%;
            color: #888;
          }
          .link-acceso {
            padding: 0 20px;
            color: #777;
            font-size: 75%;
            text-align: center;
            margin-top: 10px;
            a {
              margin: 0 5px;
            }
          }
        }
        .listado {
          height: 200px;
          min-height: 200px;
          max-height: 200px;
          overflow: auto;
          @media all and (max-width: $btMD) {
            height: 350px;
            min-height: 350px;
            max-height: 350px;
          }
          .producto {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            margin: 0 2px;
            border-bottom: 1px solid #999;
            &:first-of-type {
              * {
                border-top: 0 !important;
              }
            }
            * {
              border-bottom: 0 !important;
            }
            a.nombre {
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 60%;
              color: #000;
              outline: 0;
              padding: 0 7px;
              font-family: $fontTitilliumWebLight;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              &::before {
                content: "";
                display: block;
                @include minmaxwh(15px);
                background-color: $colorVerdeBase;
                margin: 0 15px;
                border-radius: 50%;
              }
            }
            .cantidad {
              height: 70%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20%;
              font-family: $fontTitilliumWebLight;
              color: #000;
              padding: 0 7px;
              font-size: 90%;
              padding-left: 7px;
              margin-left: 7px;
              border-left: 1px solid #777;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .precio {
              height: 70%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20%;
              max-width: 20%;
              font-family: $fontTitilliumWebLight;
              color: #000;
              padding: 0 3px;
              font-size: 90%;
              padding-left: 7px;
              margin-left: 7px;
              border-left: 1px solid #777;
              padding-right: 7px;
              margin-right: 7px;
              border-right: 1px solid #777;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .quitar {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 10%;
              i {
                padding: 10px;
                cursor: pointer;
                color: #a00;
                @include ease-transition();
                &:hover {
                  color: #f00;
                }
              }
            }
          }
        }
        .botonera {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 50px;
          .boton-continuar {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            border: 0;
            border-radius: 5px;
            font-family: $fontMontserratBold;
            text-transform: uppercase;
            font-size: 65%;
            letter-spacing: 2px;
            color: #fff;
            padding: 5px 15px;
            margin-right: 15px;
            cursor: pointer;
            outline: 0;
            text-decoration: none;
            @include ease-transition;
            &:hover {
              background-color: #000;
            }
          }
        }
      }
      .register-login {
        min-width: 100%;
        height: 250px;
        display: flex;
        position: relative;
        background-color: #fff;
        @media all and (max-width: $btMD) {
          height: 400px;
        }
        .volver {
          position: absolute;
          left: 2px;
          top: 5px;
          padding: 0 10px;
          cursor: pointer;
          color: #333;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          i {
            font-size: 120%;
            margin-right: 7px;
          }
          span {
            font-size: 80%;
            text-transform: uppercase;
          }
        }
        .formularios {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          @media all and (max-width: $btMD) {
            flex-direction: column;
          }
          .registrarse,
          .iniciar-sesion {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 50%;
            @media all and (max-width: $btMD) {
              width: 95%;
            }
            .titulo {
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                font-family: $fontMontserratBold;
                letter-spacing: 2px;
                color: #000;
                text-transform: uppercase;
              }
            }
            form {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 100%;
              input {
                margin: 10px 0;
                &:not([type="submit"]) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: $colorVerdeBase;
                  border: 0;
                  border-radius: 5px;
                  color: #fff;
                  padding: 7px;
                  font-size: 90%;
                  font-family: $fontMontserratMedium;
                  width: 90%;
                  height: 30px;
                  &::placeholder {
                    letter-spacing: 2px;
                    font-size: 90%;
                    text-transform: uppercase;
                    color: #fff;
                    font-family: $fontMontserratBold;
                  }
                }
              }
              .bottom {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                input {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #333;
                  border: 0;
                  border-radius: 5px;
                  font-family: $fontMontserratBold;
                  text-transform: uppercase;
                  font-size: 70%;
                  letter-spacing: 2px;
                  color: #fff;
                  padding: 7px 20px;
                  cursor: pointer;
                  outline: 0;
                }
                i.fa {
                  color: #000;
                }
                .respuesta {
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 10px;
                  span {
                    color: #333;
                    font-family: $fontMontserratMedium;
                    font-size: 60%;
                    white-space: pre-line;
                  }
                }
              }
            }
          }
          .separador {
            display: block;
            min-width: 2px;
            width: 2px;
            max-width: 2px;
            background-color: #333;
            height: 70%;
            @media all and (max-width: $btMD) {
              height: 2px;
              max-height: 2px;
              width: 80%;
              min-width: 80%;
              margin: 10px auto;
            }
          }
        }
      }
    }
  }
}
