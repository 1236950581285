home-promo {
  .promo-imagen {
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 50vh;
    width: 80%;
    margin: 40px auto;
    padding: 40px 0;
    @media all and (max-width: $btMD) {
      margin: 10px auto;
      padding: 0;
      height: 250px;
      width: 90%;
    }
    .promocion-vigente {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .titulo {
        width: 50%;
        text-align: center;
        @media all and (max-width: $btMD) {
          width: 90%;
        }
        span {
          font-family: Brandon-Grothesque-Light;
          font-size: 210%;
          white-space: pre-line;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
      .promo {
        width: 60%;
        margin: 10px auto;
        text-align: center;
        @media all and (max-width: $btMD) {
          width: 90%;
          text-align: justify;
        }
        strong {
          text-transform: uppercase;
          font-family: $fontMontserratMedium;
        }
      }
      .cuenta-atras {
        display: flex;
        .contenedor-relojil {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 20px 5px;

          @include minmaxwh(46px);
          background-color: #222;
          border-radius: 50%;
          color: white;
          position: relative;
          .telem {
            position: absolute;
            border-radius: 50%;
            @include minmaxwh(40px);
            border-top: 1px solid white;
            border-left: 1px solid white;
            border-right: 1px solid white;
            border-bottom: 1px solid white;
          }
          .campo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .dias,
            .horas,
            .minutos,
            .segundos {
              font-size: 90%;
              font-family: montserrat-bold;
            }
            .label {
              font-size: 50%;
            }
          }
        }
      }
    }
    .promocion-vencida,
    .no-promocion {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .texto {
        width: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        span {
          font-family: Brandon-Grothesque-Light;
          font-size: 210%;
          white-space: pre-line;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        strong {
          font-family: Brandon-Grothesque-Light;
          font-size: 210%;
          white-space: pre-line;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
    }
  }
}
