@import "../config";

body.page-spfl {
  .main-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    position: relative;
    .fondo {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("/assets/images/usuarios/fondo-spfl.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-filter: blur(7px);
      filter: blur(7px);
      z-index: 1;
      opacity: 0.5;
      background-color: #000;
    }
    .formulario {
      position: relative;
      z-index: 2;
      padding: 35px;
      width: 40vw;
      height: auto;
      background-color: #f9f9f9;
      border-radius: 10px;
      @include sombra(0 0 4px 0 #333);
      .titulo {
        span {
          text-transform: uppercase;
          color: #000;
          font-family: "verdana";
          font-size: 160%;
        }
      }
      .explicacion {
        margin: 10px 0;
        margin-bottom: 20px;
        display: block;
        p {
          margin: 0;
          font-family: "verdana";
        }
      }
      form {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        input[type="password"] {
          width: 100%;
          margin-bottom: 7px;
          border: 0;
          background-color: #000;
          color: #fff;
          height: 35px;
          padding: 0 10px;
          &::placeholder {
            font-family: "verdana";
            text-transform: uppercase;
            font-size: 80%;
          }
        }
        .subm {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          .respuesta {
            margin-right: 7px;
            span {
              font-family: "verdana";
              font-size: 90%;
              color: #444;
            }
          }
          i {
            margin-right: 7px;
          }
          input {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            border: 0;
            border-radius: 5px;
            font-family: "verdana";
            text-transform: uppercase;
            font-size: 65%;
            letter-spacing: 2px;
            color: #fff;
            padding: 5px 15px;
            cursor: pointer;
            outline: 0;
            text-decoration: none;
            @include ease-transition;
            &:disabled {
              opacity: 0.5;
            }
            &:hover:not(:disabled) {
              background-color: #000;
            }
          }
        }
      }
    }
  }
}
