@import "../config";

body.page-checkout {
  section.contenido {
    .no-products {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      span {
        font-size: 90%;
        color: #888;
      }
    }
    .titulo-principal {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      @media all and (max-width: $btMD) {
        margin-top: 15px;
        text-align: center;
      }
      &::before,
      &::after {
        content: "";
        display: block;
        height: 5px;
        width: 100px;
        margin: 0 25px;
        background-color: #000;
      }
      span {
        text-transform: uppercase;
        color: #000;
        font-family: "verdana";
        font-size: 230%;
        @media all and (max-width: $btMD) {
          font-size: 190%;
        }
      }
    }
    .explicacion-principal {
      width: 50%;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 20px;
      @media all and (max-width: $btMD) {
        width: 95%;
      }
      p {
        text-align: center;
        font-family: "verdana";
        color: #000;
        font-size: 115%;
        line-height: 2;
        b,
        u {
          margin: 0 5px;
        }
      }
    }
    .botones {
      display: flex;
      justify-content: center;
      align-items: center;
      .comprar {
        .boton {
          img {
            @include sombra(0 0 0 6px #000);
          }
        }
      }
      .presupuestar {
        .boton {
          img {
            @include sombra(0 0 0 6px #000);
          }
        }
      }
      .comprar,
      .presupuestar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 20px 35px;
        @media all and (max-width: $btMD) {
          margin: 0 auto;
        }
        .boton {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 190px;
          height: 147px;
          background-color: #dadada;
          cursor: pointer;
          @include ease-transition;
          &:hover,
          &.active {
            img {
              @include grayscale(0);
            }
          }
          // &:hover > img.verde {
          //   opacity: 1;
          //   z-index: 2;
          // }
          // &.active img.verde {
          //   opacity: 1;
          //   z-index: 2;
          // }
          @media all and (max-width: $btMD) {
            @include minmaxwh(40vw);
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            @include ease-transition;
            @include grayscale(1);
          }
        }
        .explicacion {
          margin-top: 7px;
          span {
            color: #000;
            // font-style: italic;
            font-family: "verdana";
            text-transform: uppercase;
          }
        }
      }
    }

    .orden-venta,
    .presupuesto {
      display: flex;
      flex-direction: column;
      background: #fff;
      width: 50%;
      margin: 50px auto;
      @media all and (max-width: $btMD) {
        width: 95%;
      }
      .lista {
        display: flex;
        flex-direction: column;
        background-color: #ccc;
        &.compra {
          border-top: 10px solid #000;
        }
        &.presupuestar {
          border-top: 10px solid #000;
        }
        .cabecera {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0;
          margin: 0 2px;
          border-bottom: 1px solid #999;
          .imagen {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            border-left: 0;
            border-right: 0;
            padding: 0 3px;
            color: #000;
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .nombre {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 60%;
            color: #000;
            outline: 0;
            padding: 0 10px;
            color: #000;
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .cantidad {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            color: #000;
            padding: 0 7px;
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .precio {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            color: #000;
            padding: 0 3px;
            span {
              font-family: "verdana";
              letter-spacing: 2px;
              font-size: 70%;
            }
          }
          .quitar {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            i {
              padding: 10px;
              cursor: pointer;
              color: #a00;
              @include ease-transition();
              &:hover {
                color: #f00;
              }
            }
          }
        }
        .producto {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0;
          margin: 0 2px;
          border-bottom: 1px solid #999;
          &:first-of-type {
            * {
              border-top: 0 !important;
            }
          }
          * {
            border-bottom: 0 !important;
          }
          .imagen {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            background-color: #fff;
            border-radius: 4px;
            border-left: 0;
            border-right: 0;
            padding: 0 3px;
            margin-left: 7px;
            i {
              color: #888;
            }
            img {
              object-fit: contain;
            }
          }
          a.nombre {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 60%;
            color: #000;
            outline: 0;
            padding: 0 7px;
            font-family: "verdana";
            color: #000;
            font-size: 95%;
            // &::before {
            //   content: "";
            //   display: block;
            //   @include minmaxwh(15px);
            //   background-color: #000;
            //   margin: 0 15px;
            //   border-radius: 50%;
            // }
          }
          .cantidad {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            font-family: "verdana";
            color: #000;
            // border: 1px solid #ccc;
            // border-right: 0;
            padding: 0 7px;
            font-size: 95%;
          }
          .precio {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            font-family: "verdana";
            color: #000;
            padding: 0 3px;
            font-size: 95%;
          }
          .quitar {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            i {
              padding: 10px;
              cursor: pointer;
              color: #a00;
              @include ease-transition();
              &:hover {
                color: #f00;
              }
            }
          }
        }
      }
      .agregar-mas {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #444;
          border: 0;
          border-radius: 5px;
          font-family: "verdana";
          text-transform: uppercase;
          font-size: 65%;
          letter-spacing: 2px;
          color: #fff;
          padding: 5px 15px;
          cursor: pointer;
          outline: 0;
          text-decoration: none;
          @include ease-transition;
          &:hover {
            background-color: #222;
          }
        }
      }
      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;
      }
      .botonera {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;
        .boton-comprar,
        .boton-presupuestar {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000;
          border: 0;
          border-radius: 5px;
          font-family: "verdana";
          text-transform: uppercase;
          font-size: 65%;
          letter-spacing: 2px;
          color: #fff;
          padding: 5px 15px;
          cursor: pointer;
          outline: 0;
          text-decoration: none;
          margin-bottom: 10px;
          @include ease-transition;
          &:hover {
            background-color: #000;
          }
        }
      }
    }
    .formulario-datos {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 30px;
      form {
        width: 40%;
        margin: auto;
        @media all and (max-width: $btLG) {
          width: 90%;
        }
        .campo {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          margin-bottom: 10px;
          width: 100%;
          span {
            font-family: "verdana";
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 80%;
            color: #000;
            margin-bottom: 8px;
          }
          input,
          select,
          textarea {
            width: 100%;
            font-family: "verdana";
            font-size: 90%;
            border: 0;
            border-radius: 5px;
            color: #000;
            background-color: #e1e1e1;
            padding: 7px 8px;
            &[readonly] {
              background-color: #efefef;
              color: #999;
              font-size: 80%;
            }
            &.ng-dirty.ng-valid {
              @include sombra(0 0 0 0 #000);
            }
            &.ng-dirty.ng-invalid,
            &.ng-dirty.ngInvalid {
              @include sombra(0 0 1px 1px #f14);
            }
          }
          textarea {
            resize: none;
            height: 120px;
          }
        }
        .total-precio {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          margin: 15px 0;
          span {
            font-family: "verdana";
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 80%;
            color: #000;
            margin-bottom: 7px;
            &:not(:last-of-type) {
              margin-right: 7px;
            }
            &:first-of-type {
              font-family: "verdana";
            }
          }
        }
        .metodo-pago {
          span {
            font-family: "verdana";
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 80%;
          }
          .metodos {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            .metodo {
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              label {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 4px;
                border: 1px solid #ccc;
                padding: 15px;
                img {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100px;
                  height: 50px;
                  object-fit: contain;
                }
              }
              input {
                cursor: pointer;
                margin: 15px;
                @media all and (max-width: $btMD) {
                  @include escalar(1.5);
                }
              }
            }
          }
        }

        .subm {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 30px;
          i {
            margin-right: 15px;
          }
          input {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            border: 0;
            border-radius: 5px;
            font-family: "verdana";
            text-transform: uppercase;
            font-size: 65%;
            letter-spacing: 2px;
            color: #fff;
            padding: 5px 15px;
            outline: 0;
            text-decoration: none;
            @include ease-transition;
            &:not(:disabled) {
              cursor: pointer;
              &:hover {
                background-color: #000;
              }
            }
            &:disabled {
              opacity: 0.7;
            }
          }
        }
        .ocurrio-error {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px;
          span {
            color: red;
            font-family: "verdana";
            text-transform: uppercase;
            font-size: 65%;
          }
        }
      }
    }
    .explicacion {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px;
      text-align: center;
      span {
        font-family: "verdana";
        font-size: 95%;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .boton-continuar-tp,
    .boton-continuar-mp {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
      border: 0;
      border-radius: 5px;
      font-family: "verdana";
      text-transform: uppercase;
      font-size: 65%;
      letter-spacing: 2px;
      color: #fff;
      padding: 5px 15px;
      cursor: pointer;
      outline: 0;
      text-decoration: none;
      width: 300px;
      margin: auto;
      margin-top: 25px;
      margin-bottom: 50px;
      @include ease-transition;
      &:hover {
        background-color: #000;
      }
    }
    .merge {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .explicacion {
        p {
          margin: 0;
          font-size: 80%;
        }
      }
      .botonera {
        margin: 0;
        margin-left: 15px;
      }
    }
  }
}
