@font-face {
  font-family: "montserrat-regular";
  src: url("/assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "montserrat-medium";
  src: url("/assets/fonts/Montserrat-Medium.otf");

}
@font-face {
  font-family: "montserrat-bold";
  src: url("/assets/fonts/Montserrat-Bold.otf");

}
@font-face{
  font-family:"Brandon-Grothesque-Bold";
  src:url("/assets/fonts/Brandon_bld_3.otf");
}
@font-face{
  font-family:"Brandon-Grothesque-Regular";
  src:url("/assets/fonts/Brandon_reg_4.otf");
}
@font-face{
  font-family:"Brandon-Grothesque-Light";
  src:url("/assets/fonts/Brandon_light_4.otf");
}
@font-face{
  font-family: "Brandon-Grothesque-Black";
  src:url("/assets/fonts/Brandon_blk_3.otf");
}

@font-face{
  font-family: "Brandon-Grothesque-Medium";
  src:url("/assets/fonts/Brandon_med_4.otf");
}
@font-face{
  font-family: "TitilliumWeb-Bold";
  src:url("/assets/fonts/TitilliumWeb-Bold.ttf");
}
@font-face{
  font-family: "TitilliumWeb-Regular";
  src:url("/assets/fonts/TitilliumWeb-Regular.ttf");
}
@font-face{
  font-family: "TitilliumWeb-Light";
  src:url("/assets/fonts/TitilliumWeb-Light.ttf");
}
