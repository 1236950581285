footer-section {
  * {
    box-sizing: border-box;
  }
  .footer {
    display: flex;
    background-color: #1c1c1c;
    width: 100%;
    @media all and (max-width: $btMD) {
      flex-direction: column;
      height: auto;
    }
    .columna {
      width: 40%;
      display: flex;
      flex-direction: column;
      @media all and (max-width: $btXL) {
        width: 33.33% !important;
        padding: 0 20px !important;
        .columna-contenido {
          padding: 0 10px !important;
        }
      }
      @media all and (max-width: $btMD) {
        width: 100% !important;
      }
      &.nosotros {
        .columna-contenido {
          padding: 0 30px;
        }
      }
      &.comuniquese {
        .columna-contenido {
          padding: 0 70px;
        }
      }
      &:first-child {
        width: 30%;
        .columna-contenido {
          border-left: none;
        }
      }
      &:last-child {
        width: 30%;
        .columna-contenido {
          border-right: none;
        }
      }
      .columna-titulo {
        margin: 60px auto 10px auto;
        @media all and (max-width: $btMD) {
          margin: 20px auto;
        }
        span {
          font-family: Brandon-Grothesque-Bold;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 1.5px;
        }
      }
      .columna-contenido {
        height: 100%;
        border-left: #777 solid 1px;
        border-right: #777 solid 1px;
        padding: 0 50px;
        margin-bottom: 30px;
        @media all and (max-width: $btMD) {
          border: 0;
          padding: 0 10px !important;
        }
        ul {
          padding-inline-start: 0;
          li {
            list-style: none;
            color: #fff;
            font-family: TitilliumWeb-Light;
            font-size: 80%;
            width: 90%;
            margin: 5px auto;
            display: flex;
            justify-content: flex-start;
            .items {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
            }
            a.item {
              margin: 0 10px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              color: #fff;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
              span {
                font-size: 120%;
                display: block;
                margin-bottom: 10px;
              }
            }
            .icono {
              padding-top: 2px;
              i {
                @include minmaxwh(20px);
                font-size: 120%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .redes-sociales {
          ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto;
            width: 90%;
            padding-inline-start: 0;
            li {
              margin: 0 5px;
              width: auto;
              a {
                @include minmaxwh(30px);
                border: 1px solid white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                text-decoration: none;
                @include ease-transition;
                &:hover {
                  background-color: #fff;
                  i {
                    color: #1c1c1c;
                  }
                }
                i {
                  color: white;
                }
              }
            }
          }
        }
        form {
          .campo {
            &:not(.last) {
              margin-bottom: 10px;
            }
            input,
            textarea {
              width: 100%;
              padding: 3px 16px;
              border: none;
              font-family: $fontTitilliumWebRegular;
              font-size: 90%;
              color: #000;
              resize: none;
              margin: 0;
              background-color: #fff;
              &::placeholder {
                opacity: 0.7;
              }
            }
          }
          .subm {
            margin: 0;
            background-color: #fff;
            padding: 7px 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @media all and (max-width: $btMD) {
              background-color: transparent;
            }
            &::before {
              content: "";
              width: 100%;
              height: 1px;
              background-color: #777;
              display: block;
              @media all and (max-width: $btMD) {
                display: none;
              }
            }
            input {
              margin: 0;
              font-family: $fontTitilliumWebBold;
              text-transform: uppercase;
              color: #000;
              border: 0;
              padding: 4px 0;
              width: 100px;
              text-align: right;
              font-size: 100%;
              line-height: 14px;
              margin-bottom: 7px;
              @media all and (max-width: $btMD) {
                text-align: center;
              }
              &:disabled {
                opacity: 0.5;
              }
              &:not(:disabled) {
                cursor: pointer;
              }
            }
          }
        }
        /*columna tarjetas */
        .tarjetas {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          margin-top: 20px;
          @media all and (max-width: $btMD) {
            justify-content: flex-start;
          }
          .t {
            width: 25%;
            margin: 4px 0;
            display: flex;
            justify-content: flex-end;
            @media all and (max-width: $btMD) {
              justify-content: flex-start;
            }
            img {
              height: 23px;
            }
          }
        }
        .newsletters-titulo {
          color: white;
          font-family: Brandon-Grothesque-Bold;
          text-align: right;
          margin: 10px 0;
          @media all and (max-width: $btMD) {
            text-align: center;
            margin-top: 30px;
          }
        }
        .newsletter-campo {
          display: flex;
          justify-content: flex-end;
          @media all and (max-width: $btMD) {
            justify-content: center;
          }
          form.newsletters-form {
            background-color: white;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: 250px;
            input[type="email"] {
              padding: 3px 10px;
              border: none;
              width: 65%;
              font-family: $fontTitilliumWebRegular;
              font-size: 90%;
              color: #000;
              resize: none;
              margin: 0;
              background-color: #fff;
              &::placeholder {
                opacity: 0.7;
              }
            }
            button {
              border: none;
              background: transparent;
              font-family: $fontTitilliumWebBold;
              font-size: 90%;
              text-align: right;
              padding: 0 10px;
              border: 0;
              cursor: pointer;
              &:focus {
                border: none;
                outline: 0;
              }
            }
          }
        }
      }
    }
  }
}
