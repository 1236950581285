home-destacados {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media all and (max-width: $btMD) {
    margin-top: 50px;
  }
  .titulo {
    span {
      font-family: $fontBrandonBlack;
      text-align: center;
      text-transform: uppercase;
      font-size: 210%;
      color: #333;
    }
  }
  .texto {
    margin: 20px auto;
    width: 60%;
    @media all and (max-width: $btMD) {
      width: 90%;
      margin: 10px auto;
    }
    p {
      text-align: center;
      margin: 0;
      font-family: TitilliumWeb-Regular;
      font-size: 135%;
      line-height: 130%;
      color: #333;
      @media all and (max-width: $btMD) {
        text-align: justify;
      }
    }
  }
  .buttons-categoria {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    overflow: auto;
    button.categoria {
      border: 1px solid #777;
      border-radius: 100px;
      padding: 3px 25px;
      margin: 0 12px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 100%;
      font-family: "Brandon-Grothesque-Medium";
      color: #000000;
      background-color: #ffffff;
      @include ease-transition;
      &:hover,
      &.active {
        background-color: #000000;
        color: #ffffff;
      }
      @media all and (max-width: $btMD) {
        margin: 0 6px;
        padding: 3px 15px;
      }
    }
  }
  .tira-productos {
    display: flex;
    justify-content: center;
    width: 100%;
    @media all and (max-width: $btMD) {
      width: auto;
      margin: auto;
      justify-content: flex-start;
      overflow: auto;
    }
  }
}
