slider-animado {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 500px;
  @media all and (max-width: $btMD) {
    height: 70vw;
    min-height: 70vw;
  }
  .arrow {
    position: absolute;
    height: 10%;
    width: 5%;
    top: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include ease-transition();

    background-color: #444;
    @include minmaxwh(40px);
    border-radius: 50%;
    opacity: 0.4;

    cursor: pointer;
    z-index: 2;
    &:hover {
      opacity: 1;
    }
    &.left {
      left: 5%;
    }
    &.right {
      right: 5%;
    }
    i {
      color: #fff;
    }
  }
  .indicadores {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .indicador {
      display: block;
      @include minmaxwh(11px);
      background: transparent;
      border: 1px solid #000;
      margin: 0 7px;
      border-radius: 50%;
      cursor: pointer;
      @include ease-transition();
      &:not(.active) {
        background-color: #000;
      }
      &:hover:not(.active) {
        background-color: #000;
        opacity: 0.7;
        border: 1px solid #000;
      }
    }
  }
  .contenedor {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .lista-slides {
      width: 100%;
      height: 100%;
      display: flex;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      @include ease-transition(700ms);
      @for $i from 1 through 15 {
        &.activo-#{$i} {
          -webkit-transform: translateX($i * -100%);
          transform: translateX($i * -100%);
        }
      }
      .slide {
        display: block;
        width: 100%;
        min-width: 100%;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media all and (max-width: $btMD) {
          background-size: cover;
          height: 100%;
        }
        .titulo {
          font-family: "Brandon-Grothesque-Light";
          font-size: 220%;
          width: 35%;
          line-height: 100%;
          text-align: center;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
        .linea {
          font-family: "Brandon-Grothesque-Black";
          font-size: 130%;
          text-transform: uppercase;
        }
        .promo {
          font-family: "montserrat-medium";
          font-size: 120%;
          text-transform: uppercase;
        }
        .brand {
          font-family: "Brandon-Grothesque-Black";
          letter-spacing: 1em;
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          padding: 0 22px;
          margin: 30px 0 0 0;
          font-size: 140%;
          text-transform: uppercase;
        }
      }
    }
  }
}
