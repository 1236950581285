proximamente {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  position: fixed;
  left: 10%;
  bottom: 10%;
  z-index: 1000;
  width: 80%;
  height: 200px;
  @include sombra(0 0 3px 0 #000);
  border-radius: 10px;
  .cerrar {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    opacity: 0.7;
    @include ease-transition;
    &:hover {
      opacity: 1;
    }
    i {
      font-size: 130%;
    }
  }
  .explicacion {
    text-align: center;
    p {
      color: #000;
      white-space: pre-line;
      line-height: 2;
      font-family: $fontMontserratRegular;
      font-size: 150%;
    }
  }
}
