@import "../config";

body.page-productos {
  width: 100%;
  background-color: #fff;

  .productos-contenedor {
    width: 100%;

    .menu-categorias {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 50px auto;
      overflow: auto;
      @media all and (max-width: $btLG) {
        width: 95%;
        margin: 10px auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      a {
        width: 33.33%;
        min-width: 33.33%;
        height: 180px;
        cursor: pointer;
        text-decoration: none;
        color: #000;
        @media all and (max-width: $btLG) {
          width: 100%;
          height: 80px;
          margin-bottom: 10px;
        }
        @media all and (max-width: $btXS) {
          height: 40px;
        }
        .cont-categoria {
          height: 100%;
          border: black 1px solid;
          background-size: cover;
          @include sombra(0 0 6px 0 #333);
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          position: relative;
          @media all and (max-width: $btLG) {
            background-position: center;
            border: 1px solid #ccc;
            @include sombra(0 0 0 0 #000);
          }

          &:hover,
          &.actual {
            .velo {
              opacity: 0;
            }
          }

          .titulo {
            margin: 10%;
            font-family: Brandon-Grothesque-Black;
            font-size: 150%;
            text-transform: uppercase;
            @media all and (max-width: $btMD) {
              font-size: 100%;
              color: #444;
            }
          }

          .velo {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #232323;
            opacity: 0.1;
            @include ease-transition;
            @media all and (max-width: $btMD) {
              opacity: 0.05;
            }
          }
        }
      }
    }

    .seccion-paginado {
      background: #fff;
      width: 30%;
      margin: 30px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      @media all and (max-width: $btLG) {
        width: 85%;
        margin: 15px auto;
      }
      .arrow {
        @include minmaxwh(30px);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          i {
            color: #444;
          }
        }
        i {
          @include ease-transition;
          font-size: 150%;
          color: #777;
        }
      }
      .paginado {
        display: flex;
        justify-content: center;
        align-items: center;
        .puntos {
          margin: 0 7px;
          color: #666;
        }
        .pice {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 30px;
          width: 40px;
          margin: 0 3px;
          i {
            font-size: 90%;
          }
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #aaa;
            @include ease-transition;
            &.pagactual {
              background-color: #777;
            }
          }
          &:hover {
            span:not(.pagactual) {
              background-color: #999;
            }
          }
        }
      }
    }

    .seccion-central {
      display: flex;
      width: 90%;
      margin: auto;
      @media all and (max-width: $btMD) {
        flex-direction: column;
      }

      .filtros {
        width: 250px;
        margin-right: 50px;
        @media all and (max-width: $btMD) {
          width: 100%;
          margin: 0;
          margin-bottom: 30px;
          height: 200px;
          overflow: auto;
        }
        .filtro {
          .titulo {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: #888;
            margin-bottom: 10px;
            padding: 0 10px;
            span {
              color: #fff;
              font-family: $fontBrandonBold;
              text-transform: uppercase;
            }
          }
          .lista-categorias {
            .categoria {
              margin-bottom: 15px;
              .nombre {
                @include ease-transition;
                cursor: pointer;
                align-items: center !important;
                &:hover,
                &.active {
                  &::after {
                    content: "\f219";
                    font-family: "FontAwesome";
                    font-size: 90%;
                    margin-left: 7px;
                  }
                }
              }
              & > .nombre {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                background-color: $colorGrisBase;
                margin-bottom: 10px;
                padding: 0 10px;
                color: #fff;
                span {
                  font-family: $fontBrandonBold;
                  text-transform: uppercase;
                }
              }
              .lista-subcategorias {
                max-height: 350px;
                overflow: auto;
                & > .subcategoria {
                  & > .nombre {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 4px;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #777;
                    margin-bottom: 5px;
                    color: #222;
                    &::before {
                      content: "\25aa";
                      margin-right: 5px;
                      font-size: 60%;
                    }
                    span {
                      font-family: $fontTitilliumWebBold;
                      text-transform: uppercase;
                    }
                  }
                  .lista-subcategorias2 {
                    & > .subcategoria {
                      & > .nombre {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-left: 25px;
                        margin-bottom: 10px;
                        span {
                          color: #222;
                          font-family: $fontTitilliumWebRegular;
                          text-transform: uppercase;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .contenedor-range {
            margin: 20px 0;
            .valores {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              span {
                font-family: $fontTitilliumWebLight;
              }
            }
            .input-space {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              span {
                font-family: $fontTitilliumWebLight;
              }
              &::before {
                position: absolute;
                left: 0;
                top: 9px;
                content: "";
                background-color: #292929;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                z-index: 1;
              }
              input[type="range"] {
                height: 32px;
                -webkit-appearance: none;
                width: 100%;
                @include sombra(0 0 0 0 #000);
                position: relative;
                z-index: 2;

                &:focus {
                  outline: none;
                }
                &::-webkit-slider-runnable-track {
                  width: 100%;
                  height: 5px;
                  cursor: pointer;
                  animate: 0.2s;
                  box-shadow: 0px 0px 0px #000000;
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                  border-radius: 3px;
                  border: 0px solid #010101;
                }
                &::-webkit-slider-thumb {
                  box-shadow: 0px 0px 0px #000031;
                  border: 0px solid #00001e;
                  height: 15px;
                  width: 15px;
                  border-radius: 15px;
                  background: #292929;
                  cursor: pointer;
                  -webkit-appearance: none;
                  margin-top: -8.5px;
                }
                &:focus::-webkit-slider-runnable-track {
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                }
                &::-moz-range-track {
                  width: 100%;
                  height: 5px;
                  cursor: pointer;
                  animate: 0.2s;
                  box-shadow: 0px 0px 0px #000000;
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                  border-radius: 3px;
                  border: 0px solid #010101;
                }
                &::-moz-range-thumb {
                  box-shadow: 0px 0px 0px #000031;
                  border: 0px solid #00001e;
                  height: 15px;
                  width: 15px;
                  border-radius: 15px;
                  background: #292929;
                  cursor: pointer;
                }
                &::-ms-track {
                  width: 100%;
                  height: 5px;
                  cursor: pointer;
                  animate: 0.2s;
                  background: transparent;
                  border-color: transparent;
                  color: transparent;
                }
                &::-ms-fill-lower {
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                  border: 0px solid #010101;
                  border-radius: 2px;
                  box-shadow: 0px 0px 0px #000000;
                }
                &::-ms-fill-upper {
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                  border: 0px solid #010101;
                  border-radius: 2px;
                  box-shadow: 0px 0px 0px #000000;
                }
                &::-ms-thumb {
                  margin-top: 1px;
                  box-shadow: 0px 0px 0px #000031;
                  border: 0px solid #00001e;
                  height: 15px;
                  width: 15px;
                  border-radius: 15px;
                  background: #292929;
                  cursor: pointer;
                }
                &:focus::-ms-fill-lower {
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                }
                &:focus::-ms-fill-upper {
                  background-image: linear-gradient(
                    to right,
                    #292929 15px,
                    #393939
                  );
                }
              }
            }
          }
        }
      }

      .productos {
        width: 70%;
        @media all and (max-width: $btMD) {
          width: 100%;
        }
        .no-products {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          span {
            font-family: $fontTitilliumWebLight;
            color: #444;
          }
        }

        .cp {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;

          tarjeta-producto {
            width: 30%;
            @media all and (max-width: $mqMobileXL) {
              width: 100%;
              margin: 0 auto;
            }
            .tarjeta-producto {
              @media all and (max-width: $mqMobileXL) {
                width: 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
