main-menu {
  width: 100%;
  display: block;
  position: relative;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: #fff;
  @include sombra(0 0 2px 0 #555);
  display: flex;
  flex-wrap: wrap;
  font-family: montserrat-regular;
  .redes-sociales {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #e7e7e7;
    .correo {
      a.icono {
        margin: 0 6px;
        font-size: 80%;
        color: #000;
        @media all and (max-width: $btMD) {
          display: flex;
          justify-content: center;
          align-items: center;
          @include minmaxwh(37px);
          margin: 0 5px;
          cursor: pointer;
          color: #8d8d8d;
          font-size: 125%;
          border-radius: 50px;
          text-decoration: none;
          @include ease-transition();
          background-color: #d3d6d6;
          outline: 0;
          &:hover {
            background-color: #666;
            color: #fff;
          }
        }
      }
      a.direccion {
        font-family: montserrat-regular;
        letter-spacing: 0.1em;
        font-size: 90%;
        color: #000;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        @media all and (max-width: $mqMobileXL) {
          display: none;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        list-style: none;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          @include minmaxwh(37px);
          margin: 0 5px;
          cursor: pointer;
          color: #8d8d8d;
          font-size: 125%;
          border-radius: 50px;
          text-decoration: none;
          @include ease-transition();
          background-color: #d3d6d6;
          outline: 0;
          &:hover {
            background-color: #666;
            color: #fff;
          }
        }
        button {
          &.iniciar-sesion {
            border: 1px solid #000;
            border-radius: 100px;
            padding: 7px 15px;
            background-color: #000;
            color: #fff;
            text-transform: uppercase;
            font-size: 65%;
            letter-spacing: 0.1em;
            font-family: "Brandon-Grothesque-Black";
            margin-left: 7px;
          }
        }
        font-family: "Brandon-Grothesque-Regular";
      }
    }
  }
  .menu-clearer {
    position: relative;
    opacity: 0;
    z-index: -1000;
    height: 0;
    width: 100%;
    &.flotante {
      z-index: 1;
      height: 60px;
    }
  }
  #navbar {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-right: 70px;
    position: relative;
    z-index: 2;
    &.flotante {
      position: fixed;
      left: 0;
      top: 0;
      height: 60px;
      width: 100%;
      @include sombra(0 0 3px 0 #333);
      #brand-logo {
        a {
          height: 60px;
          width: 200px;
        }
      }
    }
    #brand-logo {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 100px;
        @include ease-transition;
        @media all and (max-width: $btMD) {
          width: 50vw;
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
    & > ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @media all and (max-width: $btMD) {
        display: none;
        &.open {
          display: flex;
          flex-direction: column;
          background-color: #eee;
          position: fixed;
          top: 162px;
          width: 100%;
          left: 0;
          z-index: 1000;
          padding-bottom: 10px;
          &.flotante {
            top: 60px;
          }
        }
      }
      li {
        margin: 0 5px;
        list-style: none;
        position: relative;
        a {
          display: block;
          padding: 10px;
          cursor: pointer;
          color: #000;
          text-decoration: none;
          text-transform: uppercase;
          @include ease-transition();
          font-family: "Brandon-Grothesque-Bold";
          font-size: 100%;
          outline: 0;
          &:hover:not(.active) {
            @include sombra(0 4px 0 0 #666);
          }
          &.active {
            color: #666;
          }
          &:hover:not(.active) {
          }
        }
      }
    }

    .menu-toggler {
      display: none;
      @media all and (max-width: $btMD) {
        position: absolute;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include minmaxwh(40px);
        border: 1px solid $colorGrisBase;
        border-radius: 5px;
        @include ease-transition;
        cursor: pointer;
        &:hover,
        &:active {
          background-color: $colorGrisBase;
          i {
            color: #fff;
          }
        }
        i {
          color: $colorGrisBase;
        }
      }
    }

    .boton-carrito {
      position: absolute;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      min-width: 60px;
      cursor: pointer;
      @media all and (max-width: $btMD) {
        right: 70px;
      }
      i,
      span {
        margin-right: 4px;
      }
      span {
        font-size: 85%;
      }
    }
  }
}

@import "../checkout/login-carrito";
