mantenimiento {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($colorGrisBase, 0.7);
  text-align: center;
  padding: 20px;
  span {
    padding: 30px;
    background-color: rgba($colorGrisBase, 0.85);
    color: #fff;
    text-transform: uppercase;
    font-size: 180%;
    white-space: pre-line;
    line-height: 2;
    font-family: $fontMontserratRegular;
    @media all and (max-width: $btMD) {
      font-size: 130%;
    }
  }
}
