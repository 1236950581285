@import "../config";

body[class^="page-productos-"] {
  * {
    box-sizing: border-box;
    max-width: 100%;
  }

  background-color: #fff;

  .producto-contenedor {
    width: 100%;

    .menu-categorias {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 50px auto;
      @media all and (max-width: $btLG) {
        width: 95%;
        margin: 10px auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      a.cont-categoria {
        width: 31%;
        height: 180px;
        cursor: pointer;
        text-decoration: none;
        color: #000;
        border: black 1px solid;
        background-size: cover;
        @include sombra(0 0 6px #333);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        @media all and (max-width: $btLG) {
          width: 100%;
          height: 80px;
          margin-bottom: 10px;
          background-position: center;
          border: 1px solid #ccc;
          @include sombra(0 0 0 0 #000);
        }
        @media all and (max-width: $btXS) {
          height: 40px;
        }

        &:hover,
        &.actual {
          .velo {
            opacity: 0;
          }
        }

        .titulo {
          margin: 10%;
          font-family: Brandon-Grothesque-Black;
          font-size: 150%;
          text-transform: uppercase;
          @media all and (max-width: $btMD) {
            font-size: 100%;
            color: #444;
          }
        }

        .velo {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #232323;
          opacity: 0.1;
          @include ease-transition;
          @media all and (max-width: $btMD) {
            opacity: 0.05;
          }
        }
      }
    }

    .breadcrumb {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
      @media all and (max-width: $btMD) {
        flex-direction: column;
        align-items: flex-start;
      }
      .categoria {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        @media all and (max-width: $btMD) {
          margin-bottom: 5px;
        }
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 20px 10px;
          border-color: transparent transparent #000000 transparent;
          @media all and (max-width: $btMD) {
            display: none;
          }
        }
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 10px 0 0;
          border-color: #000000 transparent transparent transparent;
          @media all and (max-width: $btMD) {
            display: none;
          }
        }
        &:first-of-type {
          &::before {
            display: none;
          }
        }
        &:last-of-type:not(.onlyone) {
          &::after {
            display: none;
          }
        }
        @media all and (max-width: $btMD) {
          &:nth-of-type(2) {
            a {
              background-color: #333;
            }
          }
          &:nth-of-type(3) {
            a {
              background-color: #666;
            }
          }
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          font-family: Brandon-Grothesque-Black;
          font-size: 80%;
          height: 100%;
          padding: 0 10px;
          background-color: #000;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .contenedor-producto-show {
      display: flex;
      height: 80vh;
      width: 80%;
      margin: 0 auto;
      @media all and (max-width: $btLG) {
        height: auto;
        width: 90%;
        margin: 0 auto;
        flex-direction: column;
      }

      .galeria-imagenes {
        width: 170px;
        height: 100%;
        position: relative;
        margin-right: 20px;
        @media all and (max-width: $btLG) {
          width: 100%;
        }

        .scroll {
          position: absolute;
          width: 92%;
          margin-left: 4%;
          height: 40px;
          cursor: pointer;
          opacity: 0.8;
          @include ease-transition;
          z-index: 1;
          &:hover {
            opacity: 1;
          }
          &.up {
            background: linear-gradient(to bottom, #bbb, transparent);
            top: 0;
          }
          &.down {
            background: linear-gradient(to top, #bbb, transparent);
            bottom: 0;
          }
        }

        .imagenes {
          width: 100%;
          padding: 3px 10px;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          overflow: hidden;
          @media all and (max-width: $btLG) {
            flex-direction: row;
            padding: 3px 1px;
            overflow: auto;
          }
          .contenedor-imagen-galeria {
            background-color: #fff;
            @include minmaxwh(150px);
            margin-bottom: 5px;
            @include sombra(1px 1px 3px #444);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @media all and (max-width: $btLG) {
              margin: 0 10px;
            }

            img {
              object-fit: cover;
              width: 90%;
              height: 90%;
            }
          }
        }
      }

      .imagen-seleccionada {
        width: 40%;
        @media all and (max-width: $btLG) {
          width: 100%;
          margin-top: 20px;
        }

        .contenedor-imagen-seleccionada {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @include sombra(1px 1px 3px #444);
          width: 100%;
          height: 95%;

          img {
            width: 90%;
            height: 90%;
            object-fit: contain;
          }
        }
      }
      .detalles-del-producto {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        margin: 0 45px;
        width: 35%;
        @media all and (max-width: $btLG) {
          margin: 0 auto;
          width: 100%;
        }

        .titulo-producto {
          font-family: Brandon-Grothesque-Black;
          font-size: 140%;
          margin-bottom: 10px;
          // @media all and (max-width: $btLG) {
          //   margin-bottom: 10px;
          // }
        }
        .descripcion-producto {
          font-family: $fontBrandonRegular;
          font-size: 105%;
          max-height: 200px;
          overflow: auto;
          @media all and (max-width: $btLG) {
            margin-bottom: 10px;
          }
        }
        .data-producto {
          .referencia-producto {
            font-family: TitilliumWeb-Light;
            color: #333;
            strong {
              color: #000;
              font-family: Brandon-Grothesque-Regular;
            }
          }
          .precio {
            font-family: Brandon-Grothesque-Black;
            color: #006a5d;
            margin: 20px 0;
            font-size: 140%;
          }
          .rating {
            span {
              i.fa.fa-star {
                color: #ffcc00;
                cursor: pointer;
                @include ease-transition;
                &:hover {
                  font-size: 110%;
                }
              }
              i.fa.fa-star-o {
                cursor: pointer;
                @include ease-transition;
                &:hover {
                  font-size: 110%;
                }
              }
            }
            span.porcentaje {
              font-family: TitilliumWeb-Light;
              margin: 0 10px;
            }
          }
          .botones {
            margin: 10px 0;
            height: 35px;
            display: flex;
            align-items: center;
            button.aniadir-a-carrito {
              background-color: #006a5d;
              color: #fff;
              border: none;
              padding: 3px 14px;
              font-family: Brandon-Grothesque-Bold;
              letter-spacing: 0.1em;
              font-size: 110%;
              border-radius: 2px;
              height: 100%;
              cursor: pointer;
            }
            button.a-favorito {
              margin: 0 10px;
              height: 100%;
              border-radius: 2px;
              padding: 3px 17px;
              background-color: #eb3f61;
              color: #fff;
              border: none;
              font-size: 112%;
              cursor: pointer;
              span {
              }
            }
          }
          .carrito {
            font-family: Brandon-Grothesque-Bold;
            color: #555;
            span {
              margin: 0 2px;
            }
          }
        }
        .formas-envio,
        .promociones-pago {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          position: relative;
          margin-top: 10px;
          padding-top: 10px;
          border-top: 2px solid $colorVerdeBase;
          .titulo {
            span {
              font-weight: bold;
              font-size: 115%;
              font-family: $fontBrandonBold;
            }
          }
          .subtitulo {
            span {
              font-weight: bold;
              font-size: 100%;
              font-family: $fontBrandonBold;
            }
          }
          .texto-ver-mas {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .texto {
              margin-right: 5px;
              span {
                font-weight: bold;
                font-size: 135%;
                color: $colorVerdeBase;
                font-family: $fontBrandonBold;
              }
            }
            .ver-mas {
              button {
                background-color: transparent;
                border: 0;
                font-size: 120%;
                color: #44ace9;
                cursor: pointer;
                font-family: $fontBrandonBold;
              }
            }
          }
          .mas-info {
            position: absolute;
            background-color: #fff;
            padding: 15px;
            padding-top: 20px;
            margin: 15px;
            margin-top: -15px;
            border-radius: 15px;
            @include sombra(0 0 3px 0 #ccc);
            min-width: 250px;
            max-width: 30vw;
            max-height: 350px;
            overflow: auto;
            z-index: 3;
            width: 100vw;
            @media all and (max-width: $btMD) {
              max-width: 60vw;
            }
            .cerrar {
              border: 0;
              background: none;
              position: absolute;
              right: 0;
              top: 0;
              @include minmaxwh(40px);
              cursor: pointer;
              i {
                color: #555;
              }
            }
            .descripcion {
              p {
                font-family: $fontBrandonMedium;
              }
            }
            .imagen {
              width: 100%;
              margin-bottom: 10px;
              img {
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
    .seccion-lo-mas-visto {
      background-color: #fff;
      padding-top: 20px;
      margin-top: 100px;
      @media all and (max-width: $btLG) {
        width: 100%;
        margin-bottom: 15px;
      }
      .titulo-visto {
        font-family: Brandon-Grothesque-Bold;
        text-align: center;
        font-size: 200%;
      }
      .no-related {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px;
        span {
          font-family: TitilliumWeb-Light;
          color: #444;
          font-style: italic;
        }
      }
      .tira-de-productos {
        display: flex;
        justify-content: center;
        margin: 0 7%;
        @media all and (max-width: $btLG) {
          justify-content: flex-start;
          margin: 0 auto;
          overflow: auto;
        }
        tarjeta-producto {
          a {
            .top-image {
              @include sombra(3px 3px 3px #888);
            }
          }
        }
      }
    }
  }
}
