.black-screen {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 5;
}
.form-presupuesto {
  position: fixed;
  min-height: 40vh;
  width: 50vw;
  left: 25vw;
  top: 20vh;
  z-index: 6;
  background-color: #fff;
  @include sombra(0 0 3px 0 #333);
  color: #000;
  padding: 30px 15px;

  .cierre {
    position: absolute;
    top: 10px;
    right: 15px;
    font-weight: bold;
    color: #aaa;
    cursor: pointer;
    @include ease-transition(100ms);
    &:hover {
      color: #fff;
    }
  }

  .titulo {
    margin: 15px 0;
    span {
      text-transform: uppercase;
    }
  }

  form {
    .campo {
      margin: 15px 0;
      input {
        width: 100%;
        padding: 7px;
        border: 1px solid #ccc;
        border-radius: 5px;
        &:required {
          @include sombra(inset -5px 0 0 #f99);
        }
      }
    }
    input[type="submit"] {
      width: 100%;
      padding: 7px;
      border: 0;
      border-radius: 5px;
      background-color: #ccc;
      opacity: 1;
      cursor: pointer;
      &:disabled {
        cursor: auto;
        opacity: 0.4;
      }
      &:hover:not(:disabled) {
        background-color: #5f5;
      }
    }
    .sent {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      i {
        color: #000;
      }
    }
    .imprimir {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      i {
        margin-left: 7px;
      }
    }
  }
}
